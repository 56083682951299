import {React, useState} from "react";
import {BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import {
    Collapse, DropdownItem,
    DropdownMenu, DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarText,
    NavbarToggler,
    NavItem,
    NavLink, UncontrolledDropdown
} from "reactstrap";
import HomePage from "../views/HomePage";

const MainRouting = (props) => {

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <Navbar color="black" sticky="top" dark>
                <NavbarBrand href="/">
                    OSRS - Clan DAD - **Work in Progress**
                </NavbarBrand>
            </Navbar>
            <Routes>
                <Route exact path="/" element={<HomePage />}/>
            </Routes>
        </div>
    )
}

export default MainRouting;