import React from "react"
import Theme from "../css/_theme.module.scss";
import classNames from "classnames";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import Logo from "../assets/logo/logo_large.png";

const HomePage = () => {


    return (
        <div className={classNames(Theme.DivBackgroundWrapper)} style={{textAlign: "center"}}>
            <br/>
            <br/>
            <img src={Logo} alt="logo" style={{width: "450px"}}/>
            <br/>
            <Link to={"https://discord.com/invite/2hqgVdTtdH "}>
                <Button style={{backgroundColor: "#7289da", minHeight: "30px", minWidth: "250px"}}>DISCORD</Button>
            </Link>
            <br/>
            <br/>
            <Link to={"https://wiseoldman.net/groups/6494"}>
                <Button style={{backgroundColor: "#2e45f2", minHeight: "30px", minWidth: "250px"}}>WoM</Button>
            </Link>
        </div>
    )
}

export default HomePage;