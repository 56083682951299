import './App.css';
import Routing from "./routes/_routing";
import {AppContext} from "./context/AppContext";

function App() {
  return (
      <AppContext.Provider style={{minWidth: "475px"}}>
        <Routing />
      </AppContext.Provider>
  );
}

export default App;
