import { BrowserRouter as Router} from 'react-router-dom';
import MainRouting from "./MainRouting";

const Routing = () => {

    return (
        <Router>
            <MainRouting />
        </Router>
    )
}

export default Routing;